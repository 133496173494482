#failed-verification {
  display: block;
  margin-top: 14px;
}

#failed-verification p {
  color: #bf4343;
}

#letsencrypt-domain-field {
  width: 270px;
  height: 37px;
  border-radius: 6px;
  border: solid 1px #bfbfbf;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 5px;
  font-size: 16px;
}

.onepassword-redirect {
  margin-top: 20px;
  padding: 10px;
  padding-top: 12px;
  height: 45px;
  width: 80%;
  border: 1px solid #1a8cff;
  background-color: #1a8cff;
  color: #ffffff;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  border-radius: 50px;
}

.onepassword-redirect:hover {
  background-color: #0572ec;
  color: white;
  cursor: pointer;
}

.tinyimg {
  height: 25px;
  width: 25px;
  float: left;
  margin-top: -2px;
  margin-left: 20px;
  margin-right: -10px;
}

#letsencrypt-info {
  width: 80%;
  display: block;
  padding: 9.5px;
  color: #4d5d8c;
  background-color: #f8fcff;
  border: 1px solid #b3d9ff;
  border-radius: 4px;
}
