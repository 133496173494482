body {
  background-color: #f2f2f2;
}

h2,
p {
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
}

h2 {
  margin: 0;
  line-height: 25px;
  font-weight: 500;
}

p {
  color: #888888;
  font-size: 15px;
  margin: 0;
  width: 400px;
}

p button {
  background: none;
  border: none;
  padding: 0px !important;
  font: inherit;
  font-size: 15px !important;
  border-bottom: 1px solid #444;
  cursor: pointer;
}

#scim-bridge-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 504px;
  margin: 60px auto;
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 1px 1px 30px lightgrey;
}

#try-reloading {
  padding: 10px;
  width: 80%;
  font-size: 13px;
}

.verify {
  width: 72px;
  height: 33px;
  border-radius: 50px;
  border: 1px solid #4da246;
  background-color: #4da246;
  color: #ffffff;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  margin: 0 10px;
}

.verify:hover {
  background-color: #448f3d;
  cursor: pointer;
}

.row {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  justify-items: flex-start;
  padding: 30px 44px 17px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
}

.row h2 {
  font-size: 16px;
  margin: 0;
  line-height: 25px;
}

.status {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background-color: #edeff2;
  margin-bottom: 10px;
}

.description {
  text-align: left;
  width: 100%;
}

.hidden {
  display: none !important;
}

.checked {
  display: block !important;
  background-color: #50b347;
  width: 24px;
  height: 24px;
  object-fit: scale-down;
  border-radius: 50px;
}

.healthy {
  display: none;
  background-color: #50b347;
}

.unhealthy {
  display: none;
  background-color: #a00d0c;
}

.unknown {
  display: none;
  background-color: #fead00;
}

.errormsg {
  color: #bf4343;
}

.notification {
  color: #50b347;
}

.big-button {
  width: 72px;
  height: 33px;
  border-radius: 50px;
  border: 1px solid #4da246;
  background-color: #4da246;
  color: #ffffff;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  margin: 0 10px;
}

#scim-bridge-logo {
  width: 128px;
  position: relative;
  bottom: 50px;
}

#top-bar {
  width: 100%;
  height: 36px;
  background-color: #f7f7f7;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 40px;
}

li {
  list-style-position: inside;
}
