#bearer-token-field {
  width: 270px;
  border-radius: 6px;
  border: solid 1px #bfbfbf;
  margin-top: 12px;
  padding: 5px;
  font-size: 16px;
}

#bearer-token-error {
  padding-top: 10px;
}
