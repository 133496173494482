p.logs {
  font-size: 15px;
  margin-top: 10px !important;
  line-height: 25px;
  color: #000000;
}

div#logout {
  display: block;
  padding: 20px 5px;
}

button#logout {
  background-color: #1a8cff;
  border: 1px solid #1a8cff;
}

button#logout:hover {
  background-color: #0572ec;
  color: white;
  cursor: pointer;
}
