.back-button {
  margin: 20px 0px 10px 0px;
  padding: 10px;
  padding-top: 12px;
  height: 45px;
  width: 80%;
  border: 1px solid #1a8cff;
  background-color: #1a8cff;
  color: #ffffff;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  border-radius: 50px;
}

.back-button:hover {
  background-color: #0572ec;
  color: white;
  cursor: pointer;
}

.container {
  text-align: center;
}

.info {
  margin-top: 10px;
  width: auto;
}
